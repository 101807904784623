import React, { Component } from "react";
import { ajaxRequesterPublic } from "../../services/ajax-requester-public";
import {
  Row,
  Col,
  Descriptions,
  Card,
  Typography,
  Button,
  Table,
  Radio,
  Drawer,
  Select,
  Progress,
} from "antd";
import PageTitle from "../../components/common/page-title";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "../../assets/css/leaflet.css";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import MapSearchBox from "../../components/common/map-searchbox";
import {
  DownloadOutlined,
  FileImageOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import ListFiltersPublic from "../../components/common/list-filters-public";
import { exportPlanToPPTx } from "./plan-to-ppt";
import { exportPlanToZip } from "./plan-to-zip";
import * as dayjs from "dayjs";
import PhotoGallery from "../../components/common/photo-gallery";
import { exportPlanToXlsx } from "./plan-to-xlsx";
import ExportReport from "../campaign/export-report";

class PlanView extends Component {
  constructor(props) {
    super(props);
    this.popup = React.createRef();
    this.state = {
      results: "",
      inventory_details: [],
      inventory_details_default: [],
      inventory_details_default_filter: [],
      isLoading: true,
      data: [],
      columns: [],
      filterColumns: [],
      pagination: {
        current: 1,
        pageSize: 2000,
      },
      inventoryType: "hainventory",
      defaultPosition: {
        zoom: 8,
        center: {
          lat: 22.8587,
          lng: 72.3924,
        },
      },
      screen: "plan-view",
      options: [],
      activeTab: "Home",
      isExportReportDrawer: false,
      mountingDropdownList: ["All"],
      monitoringDropdownList: ["All"],
      unmountingDropdownList: ["All"],
    };
  }

  // Get Plan Details
  getDetails = () => {
    const path =
      this.state.page === "plan"
        ? "plan/publish?planid="
        : "campaign/publish?campaignid=";

    const id = this.props.match.params.id;

    var reqUrl = path + id;
    var reqObj = "";

    ajaxRequesterPublic(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({
          results: data.response,
          data:
            this.state.page === "plan"
              ? data.response.plan_inventory_details
              : data.response.campaign_inventory_details,
          inventory_details:
            this.state.page === "plan"
              ? data.response.plan_inventory_details
              : data.response.campaign_inventory_details,
          inventory_details_default:
            this.state.page === "plan"
              ? data.response.plan_inventory_details
              : data.response.campaign_inventory_details,
          inventory_details_default_filter:
            this.state.page === "plan"
              ? data.response.plan_inventory_details
              : data.response.campaign_inventory_details,

          isLoading: false,
        });
        this.state.page === "plan"
          ? this.setColumns()
          : this.setCampaignColumns();
      },
      "GET"
    );
  };

  // Get Plan Details
  getOperationList = (filter) => {
    const { id } = this.props.match.params;
    const { activeTab } = this.state;

    let listType = "";

    if (activeTab === "Installation Photos") {
      listType = "mounting";
    }

    if (activeTab === "Monitoring Photos") {
      listType = "monitoring";
    }

    if (activeTab === "Uninstallation Photos") {
      listType = "unmounting";
    }

    let appliedFilter = "";

    if (filter && filter !== "All" && activeTab === "Installation Photos") {
      appliedFilter = "&columnName=creative_name&columnValue=" + filter;
    }

    if (filter && filter !== "All" && activeTab === "Monitoring Photos") {
      appliedFilter = "&columnName=monitoring_name&columnValue=" + filter;
    }

    if (filter && filter !== "All" && activeTab === "Uninstallation Photos") {
      appliedFilter = "&columnName=creative_name&columnValue=" + filter;
    }

    var reqUrl =
      "campaign/" +
      listType +
      "/list/publish?campaignId=" +
      id +
      "&pageno=1&pagesize=999" +
      appliedFilter;
    var reqObj = "";

    ajaxRequesterPublic(
      reqUrl,
      reqObj,
      (data) => {
        this.setState(
          {
            data: data?.response || [],
            inventory_details: data?.response || [],
            inventory_details_default: data?.response || [],
            inventory_details_default_filter: data?.response || [],
          },
          () => this.setCampaignColumns()
        );
      },
      "GET"
    );
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        width: 110,
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Area",
        dataIndex: "area",
      },
      {
        title: "Location",
        dataIndex: "location",
        render: (text, record) =>
          text +
          (record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Size",
        dataIndex: "size",
      },
      {
        title: "Light",
        dataIndex: "light_type",
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "Photo",
        dataIndex: "ha_images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Start Date",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
      },
      {
        title: "End Date",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
      },
      {
        title: "Available Date",
        dataIndex: "available_from",
        render: (text) =>
          dateFormatter(text) === dateFormatter(new Date())
            ? "Available"
            : dateFormatter(text),
      },
      {
        title: "Monthly Cost",
        dataIndex: "monthly_rate",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Cost",
        dataIndex: "rental_cost",
        render: (text) => currencyFormatter(parseInt(text)),
      },
    ];

    const filterColumns = [
      { columnName: "media_type", displayName: "Media" },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area" },
      { columnName: "location", displayName: "Location", default: true },
      { columnName: "size", displayName: "Size" },
      { columnName: "light_type", displayName: "Light" },
      { columnName: "quantity", displayName: "Qty" },
      { columnName: "images", displayName: "Photo" },
      { columnName: "start_date", displayName: "Start Date" },
      { columnName: "end_date", displayName: "End Date" },
      { columnName: "available_from", displayName: "Available Date" },
      { columnName: "monthly_rate", displayName: "Monthly Cost" },
      { columnName: "rental_cost", displayName: "Cost" },
    ];

    this.setState({ columns, columnsDefault: columns, filterColumns });
  };

  // Setting Campaign Columns
  setCampaignColumns = () => {
    let columns = [
      {
        title: "Sr",
        dataIndex: "sr",
        render: (text, record, key) => (
          <span style={{ whiteSpace: "nowrap" }}>{key + 1}</span>
        ),
      },
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        width: 110,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text +
          (record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Site Start Date",
        dataIndex: "start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Site End Date",
        dataIndex: "end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Actual Mounting Date",
        dataIndex: "mounting_date",
        render: (text, record) =>
          record?.campaign_status === "Running" ||
          record?.campaign_status === "Completed"
            ? dateFormatter(record?.mounting_date)
            : "",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Site Status",
        dataIndex: "campaign_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Mounting Photos",
        dataIndex: "first_mounting_photos",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.first_mounting_photos?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record, "first_mounting_photos")}
          />
        ),
      },
    ];

    const filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      { columnName: "sr", displayName: "Sr", hideFilter: true },
      { columnName: "media_type", displayName: "Media" },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area" },
      { columnName: "location", displayName: "Location", default: true },
      { columnName: "size", displayName: "Size" },
      { columnName: "light_type", displayName: "Light" },
      { columnName: "quantity", displayName: "Qty" },
      {
        columnName: "start_date",
        displayName: "Site Start Date",
        hideFilter: true,
      },
      {
        columnName: "end_date",
        displayName: "Site End Date",
        hideFilter: true,
      },
      {
        columnName: "mounting_date",
        displayName: "Actual Mounting Date",
        hideFilter: true,
      },
      { columnName: "campaign_status", displayName: "Site Status" },
      {
        columnName: "mounting_photos",
        displayName: "Mounting Photos",
        hideFilter: true,
      },
    ];

    const { activeTab } = this.state;

    if (activeTab === "Installation Photos") {
      columns.splice(9, 1, {
        title: "Creative",
        dataIndex: "creative_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      columns.splice(12, 1);

      filterColumns.splice(10, 1, {
        columnName: "creative_name",
        displayName: "Creative",
      });

      filterColumns.splice(13, 1);
    }

    if (activeTab === "Monitoring Photos") {
      columns.splice(10, 1, {
        title: "Monitoring Date",
        dataIndex: "monitoring_performed_date",
        render: (text) => text !== "0001-01-01T00:00:00" && dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      columns.splice(11, 1, {
        title: "Photo Type",
        dataIndex: "monitoring_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      columns.splice(12, 1, {
        title: "Monitoring Status",
        dataIndex: "monitoring_done",
        render: (text) => (text ? "Done" : "Pending"),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      columns.splice(13, 1, {
        title: "Monitoring Photos",
        dataIndex: "monitoring_photos",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.monitoring_photos?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record, "monitoring_photos")}
          />
        ),
      });

      filterColumns.splice(10, 1, {
        columnName: "monitoring_performed_date",
        displayName: "Monitoring Date",
        hideFilter: true,
      });

      filterColumns.splice(11, 1, {
        columnName: "monitoring_type",
        displayName: "Photo Type",
      });

      filterColumns.splice(12, 1, {
        columnName: "monitoring_done",
        displayName: "Monitoring Status",
      });

      filterColumns.splice(13, 2, {
        columnName: "monitoring_photos",
        displayName: "Monitoring Photos",
        hideFilter: true,
      });
    }

    if (activeTab === "Uninstallation Photos") {
      columns.splice(9, 1, {
        title: "Creative",
        dataIndex: "creative_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      columns.splice(10, 1, {
        title: "Uninstallation Date",
        dataIndex: "unmounting_date",
        render: (text) => text !== "0001-01-01T00:00:00" && dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      columns.splice(11, 2, {
        title: "Site Status",
        dataIndex: "operation_status",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      });

      columns.splice(12, 1, {
        title: "Unmounting Photos",
        dataIndex: "unmounting_photos",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.unmounting_photos?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record, "unmounting_photos")}
          />
        ),
      });

      filterColumns.splice(10, 1, {
        columnName: "creative_name",
        displayName: "Creative",
      });
      filterColumns.splice(11, 1, {
        columnName: "unmounting_date",
        displayName: "Uninstallation Date",
        hideFilter: true,
      });
      filterColumns.splice(12, 3, {
        columnName: "operation_status",
        displayName: "Site Status",
      });
      filterColumns.splice(13, 1, {
        columnName: "unmounting_photos",
        displayName: "Unmounting Photos",
      });
    }

    this.setState({ columns, columnsDefault: columns, filterColumns });
  };

  // Handle Photos SlideShoe
  handlePhotos = (record, type) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record
        ? type !== "first_mounting_photos"
          ? record?.ha_images ||
            record?.mounting_photos ||
            record?.monitoring_photos ||
            record?.unmounting_photos ||
            record?.first_mounting_photos
          : record?.first_mounting_photos
        : "",
      photosTitle: record ? record : "",
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    let inventory_details = [...this.state.inventory_details_default_filter];

    inventory_details = inventory_details.map((x) =>
      filterValue
        ? filterColumn.columnName !== "general"
          ? (
              filterColumn.columnName === "quantity" ||
              filterColumn.columnName === "sqft" ||
              filterColumn.columnName === "base_rate" ||
              filterColumn.columnName === "monthly_rate" ||
              filterColumn.columnName === "rental_cost"
                ? x[filterColumn.columnName].toString().includes(filterValue)
                : x[filterColumn.columnName]
                    ?.toLowerCase()
                    ?.includes(filterValue.toLowerCase())
            )
            ? {
                ...x,
                filter_row: "show",
              }
            : {
                ...x,
                filter_row: "hide",
              }
          : x.media_type.toLowerCase().includes(filterValue.toLowerCase()) ||
            x.inventory_code
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            x.city.toLowerCase().includes(filterValue.toLowerCase()) ||
            x.area.toLowerCase().includes(filterValue.toLowerCase()) ||
            x.location.toLowerCase().includes(filterValue.toLowerCase())
          ? {
              ...x,
              filter_row: "show",
            }
          : {
              ...x,
              filter_row: "hide",
            }
        : // If Filter Value Blank
          {
            ...x,
            filter_row: "show",
          }
    );

    this.setState({ inventory_details });
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    let inventory_details = [...this.state.inventory_details];

    inventory_details.sort((a, b) =>
      sorter.order === "ascend"
        ? typeof a[sorter.field] !== "number"
          ? a[sorter.field]?.localeCompare(b[sorter.field]?.toLowerCase())
          : a[sorter.field] - b[sorter.field]
        : typeof b[sorter.field] !== "number"
        ? b[sorter.field]?.localeCompare(a[sorter.field]?.toLowerCase())
        : b[sorter.field] - a[sorter.field]
    );

    this.setState({ inventory_details });
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  handleDownload = (file) => {
    switch (file) {
      case "PPT":
        this.setState({ isDownloading: true });
        exportPlanToPPTx(
          this.state.results,
          {
            ...this.state.configurationDetails,
            exportName: `${this.state.results.display_name} - ${dayjs().format(
              "Do MMMM YYYY"
            )}.pptx`,
          },
          ["Card Rates", "Discounted Rates", "Available From"],
          () => {
            this.setState({ isDownloading: false });
          }
        );
        break;

      case "ZIP":
        exportPlanToZip(this.state.results, {
          exportName: `${this.state.results.display_name} - ${dayjs().format(
            "Do MMMM YYYY"
          )}.zip`,
        });
        break;

      case "XLSX":
        exportPlanToXlsx(
          this.state.results,
          {
            ...this.state.configurationDetails,
            exportName: `${this.state.results.display_name} - ${dayjs().format(
              "Do MMMM YYYY"
            )}.xlsx`,
          },
          [
            "Sr",
            "Media Type",
            "Qty",
            "Lat",
            "Long",
            "IID",
            "District",
            "City",
            "Area",
            "Location",
            "W",
            "H",
            "Size",
            "SQFT",
            "Traffic From",
            "Traffic To",
            "Light",
            "Available From",
            "Start Date",
            "End Date",
            "Card Rate Per Month",
            "Discounted Monthly Rate",
            "Display Cost",
            "Printing Cost",
            "Mounting Cost",
            "Total",
            "GST (18%)",
            "Grand Total",
          ]
        );
        break;

      default:
        break;
    }
  };

  // Setup Background Colors
  setColors = (record) => {
    return record.campaign_status === "Completed"
      ? !record.filter_row
        ? "row-bg-redgray"
        : record.filter_row === "show"
        ? "row-bg-redgray"
        : "row-bg-redgray hide-filtered-row"
      : record.campaign_status === "Running"
      ? !record.filter_row
        ? "row-bg-yellow"
        : record.filter_row === "show"
        ? "row-bg-yellow"
        : "row-bg-yellow hide-filtered-row"
      : // Status not
      !record.filter_row
      ? ""
      : record.filter_row === "show"
      ? ""
      : "hide-filtered-row";
  };

  handleClosePopup = () => {
    this.popup.current.leafletElement.options.leaflet.map.closePopup();
  };

  // Set Tabs
  setTabs = () => {
    const options = [
      { label: "Home", value: "Home" },
      { label: "Installation Photos", value: "Installation Photos" },
      { label: "Monitoring Photos", value: "Monitoring Photos" },
      { label: "Uninstallation Photos", value: "Uninstallation Photos" },
    ];

    this.setState({ options });
  };

  // Tab Change
  onTabChange = (e) => {
    if (e.target.value === "Home") {
      this.setState(
        { activeTab: e.target.value, filterColumns: [], filterValue: "" },
        () => this.getDetails()
      );
    } else {
      this.setState(
        { activeTab: e.target.value, filterColumns: [], filterValue: "" },
        () => this.getOperationList()
      );
    }
  };

  handleDropdownChange = (value) => {
    const { activeTab } = this.state;

    let filter = "";

    if (activeTab === "Installation Photos") {
      filter = {
        columnName: "creative_name",
        displayName: "Creative",
      };
    } else if (activeTab === "Uninstallation Photos") {
      filter = {
        columnName: "creative_name",
        displayName: "Creative",
      };
    } else {
      filter = {
        columnName: "monitoring_name",
        displayName: "Monitoring",
      };
    }

    this.handleFilters(filter, value !== "All" ? value : "");
  };

  getStatus = (type) => {
    const { activeTab } = this.state;
    let total = 0;
    let count = 0;
    let percent = 0;

    if (activeTab === "Home") {
      this?.state?.data.map(
        (x) =>
          x?.campaign_status &&
          ((total = total + 1),
          (x?.campaign_status === "Running" ||
            x?.campaign_status === "Completed") &&
            (count = count + 1))
      );
    }

    if (activeTab === "Installation Photos") {
      this?.state?.data.map(
        (x) =>
          x?.operation_status &&
          ((total = total + 1),
          (x?.operation_status === "Mounted" ||
            x?.operation_status === "Pending Unmounted Flex" ||
            x?.operation_status === "Pending Unmounting Photo") &&
            (count = count + 1))
      );
    }

    if (activeTab === "Monitoring Photos") {
      this?.state?.data.map(
        (x) =>
          x?.operation_status &&
          ((total = total + 1), x?.monitoring_done && (count = count + 1))
      );
    }

    if (activeTab === "Uninstallation Photos") {
      this?.state?.data.map(
        (x) =>
          x?.operation_status &&
          ((total = total + 1),
          x?.operation_status === "Pending Unmounted Flex" &&
            (count = count + 1))
      );
    }

    percent = parseInt((count * 100) / total);

    return { total, count, percent };
  };

  // Gettng Seller Details
  getConfigurationDetails = () => {
    let reqUrl = "configuration/detail";
    let reqObj = "";

    ajaxRequesterPublic(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ configurationDetails: data });
      },
      "GET"
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const page = this.props.match.path.includes("plan") ? "plan" : "campaign";
    this.setState({ page }, () => this.getDetails());
    this.setTabs();
    this.getConfigurationDetails();
  }

  render() {
    const {
      page,
      defaultPosition,
      data,
      results,
      isLoading,
      columns,
      inventory_details,
      filterColumns,
      isShowPhotos,
      photos,
      screen,
      photosTitle,
      options,
      activeTab,
      isExportReportDrawer,
    } = this.state;

    const { Title } = Typography;

    const { Option } = Select;

    let mountingDropdownList = ["All"];
    let monitoringDropdownList = ["All"];
    let unmountingDropdownList = ["All"];

    if (activeTab === "Installation Photos") {
      data.length > 0 &&
        data.map(
          (x) => x?.creative_name && mountingDropdownList.push(x?.creative_name)
        );
    }

    if (activeTab === "Monitoring Photos") {
      data.length > 0 &&
        data.map(
          (x) =>
            x?.monitoring_name &&
            monitoringDropdownList.push(x?.monitoring_name)
        );
    }

    if (activeTab === "Uninstallation Photos") {
      data.length > 0 &&
        data.map(
          (x) =>
            x?.creative_name && unmountingDropdownList.push(x?.creative_name)
        );
    }

    mountingDropdownList = [...new Set(mountingDropdownList)];
    monitoringDropdownList = [...new Set(monitoringDropdownList)];
    unmountingDropdownList = [...new Set(unmountingDropdownList)];

    return (
      <React.Fragment>
        <Row>
          <Col span={24} xs={24} xl={24}>
            <PageTitle
              titleText={results.customer_name + " - " + results.display_name}
            />

            <Row gutter={16}>
              <Col
                span={page === "plan" ? 16 : 24}
                xs={24}
                xl={page === "plan" ? 16 : 24}
              >
                <div
                  style={{
                    width: "100%",
                    height: "454px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {data && (
                    <Map
                      center={defaultPosition.center}
                      zoom={defaultPosition.zoom}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      {data.length > 0 &&
                        data.map(
                          (item, key) =>
                            item.latitude &&
                            item.longitude && (
                              <Marker
                                key={key}
                                position={[item.latitude, item.longitude]}
                              >
                                <Popup closeButton={false} ref={this.popup}>
                                  <div className="leaflet-map-popup">
                                    <b>
                                      {item.inventory_code} - {item.media_type}
                                    </b>

                                    <div>{item.location}</div>
                                    <div>{item.inventory_location}</div>
                                    <div>
                                      Size : {item.size}{" "}
                                      {item.quantity > 1 &&
                                        ", Quantity : " + item.quantity}
                                    </div>

                                    {page !== "campaign" && (
                                      <div>
                                        Card Rate :{" "}
                                        {currencyFormatter(
                                          parseInt(item.card_rate)
                                        )}
                                      </div>
                                    )}

                                    {page === "campaign" && (
                                      <React.Fragment>
                                        <div>
                                          Display Cost per Month :{" "}
                                          {currencyFormatter(
                                            parseInt(item?.monthly_rate)
                                          )}
                                        </div>

                                        <div>
                                          Installation Date :{" "}
                                          {dateFormatter(item?.mounting_date)}
                                        </div>

                                        <div>
                                          {item?.first_mounting_photos?.length >
                                            0 && (
                                            <Button
                                              type="link"
                                              onClick={() =>
                                                this.handlePhotos(
                                                  item,
                                                  activeTab === "Home"
                                                    ? "first_mounting_photos"
                                                    : ""
                                                )
                                              }
                                              icon={<FileImageOutlined />}
                                              style={{ padding: "0px" }}
                                            >
                                              Installation Photo
                                            </Button>
                                          )}
                                        </div>
                                      </React.Fragment>
                                    )}

                                    <Button
                                      icon={<CloseOutlined />}
                                      onClick={this.handleClosePopup}
                                      style={{
                                        position: "absolute",
                                        right: "4px",
                                        top: "4px",
                                        border: "0px none",
                                        background: "none",
                                      }}
                                    ></Button>
                                  </div>
                                </Popup>
                              </Marker>
                            )
                        )}

                      <MapSearchBox />
                    </Map>
                  )}
                </div>
              </Col>
              <Col span={8} xs={24} xl={8}>
                {page === "plan" && (
                  <Card
                    title={
                      <Title level={5}>
                        {page === "plan" ? "Plan Summary" : "Campaign Summary"}
                      </Title>
                    }
                    bodyStyle={{ paddingBottom: "0px" }}
                    loading={isLoading}
                    size="small"
                  >
                    <Descriptions column={1}>
                      <Descriptions.Item
                        label="Rental Charges"
                        className="ant-text-right"
                      >
                        <b>
                          {currencyFormatter(
                            parseInt(results.total_display_cost)
                          )}
                        </b>
                      </Descriptions.Item>

                      <Descriptions.Item
                        label="Printing Charges"
                        className="ant-text-right"
                      >
                        <b>
                          {currencyFormatter(
                            parseInt(results.total_printing_cost)
                          )}
                        </b>
                      </Descriptions.Item>

                      <Descriptions.Item
                        label="Installation Charges"
                        className="ant-text-right"
                      >
                        <b>
                          {currencyFormatter(
                            parseInt(results.total_installing_cost)
                          )}
                        </b>
                      </Descriptions.Item>

                      <Descriptions.Item
                        label="Total"
                        className="ant-text-right"
                      >
                        <b>
                          {currencyFormatter(
                            parseInt(
                              results.total_display_cost +
                                results.total_printing_cost +
                                results.total_installing_cost
                            )
                          )}
                        </b>
                      </Descriptions.Item>

                      <Descriptions.Item
                        label={"GST @" + results.gst_per + "%"}
                        className="ant-text-right"
                      >
                        <b>{currencyFormatter(parseInt(results.gst_amount))}</b>
                      </Descriptions.Item>

                      <Descriptions.Item
                        label={<b>Grand Total</b>}
                        className="ant-text-right"
                        style={{
                          borderTop: "solid 1px #f0f0f0",
                          paddingTop: "12px",
                        }}
                      >
                        <b>
                          {currencyFormatter(parseInt(results.total_amount))}
                        </b>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                )}

                {page === "plan" && (
                  <Card
                    title={<Title level={5}>Downloads</Title>}
                    loading={isLoading}
                    style={{ marginTop: "8px" }}
                    size="small"
                  >
                    <div style={{ marginLeft: "-16px" }}>
                      <Row>
                        <Col span="12">
                          <Button
                            icon={<DownloadOutlined />}
                            type="link"
                            onClick={() => this.handleDownload("XLSX")}
                          >
                            Excel
                          </Button>
                        </Col>
                        <Col span="12">
                          <Button
                            icon={
                              this?.state?.isDownloading ? (
                                <LoadingOutlined />
                              ) : (
                                <DownloadOutlined />
                              )
                            }
                            type="link"
                            onClick={() => this.handleDownload("PPT")}
                          >
                            PPTPhotoalbum
                          </Button>
                        </Col>
                      </Row>

                      <Row>
                        <Col span="12">
                          <Button
                            icon={<DownloadOutlined />}
                            type="link"
                            onClick={() => this.handleDownload("ZIP")}
                          >
                            Photographs ZIP
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}
              </Col>
            </Row>

            <Row style={{ marginTop: "16px" }}>
              <Col span={24} xs={24} xl={24}>
                {page !== "plan" && (
                  <Row>
                    <Col span={12} xs={24} xl={12}>
                      <Radio.Group
                        options={options}
                        onChange={this.onTabChange}
                        value={
                          options.find((x) => x.value === activeTab)
                            ? activeTab
                            : ""
                        }
                        optionType="button"
                        buttonStyle="solid"
                        loading={true}
                        style={{ marginBottom: "16px" }}
                      />

                      {activeTab === "Installation Photos" && (
                        <Select
                          placeholder="Select Creative"
                          className="mobile-public-dropdown "
                          style={{ marginLeft: "16px", minWidth: "230px" }}
                          defaultValue="All"
                          onChange={this.handleDropdownChange}
                        >
                          {mountingDropdownList.map((x) => (
                            <Option key={x} value={x}>
                              {x}
                            </Option>
                          ))}
                        </Select>
                      )}

                      {activeTab === "Monitoring Photos" && (
                        <Select
                          placeholder="Select Monitoring"
                          style={{ marginLeft: "16px", minWidth: "230px" }}
                          className="mobile-public-dropdown"
                          defaultValue="All"
                          onChange={this.handleDropdownChange}
                        >
                          {monitoringDropdownList.map((x) => (
                            <Option key={x} value={x}>
                              {x}
                            </Option>
                          ))}
                        </Select>
                      )}

                      {activeTab === "Uninstallation Photos" && (
                        <Select
                          placeholder="Select Creative"
                          className="mobile-public-dropdown "
                          style={{ marginLeft: "16px", minWidth: "230px" }}
                          defaultValue="All"
                          onChange={this.handleDropdownChange}
                        >
                          {unmountingDropdownList.map((x) => (
                            <Option key={x} value={x}>
                              {x}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Col>

                    <Col span={12} xs={24} xl={12}>
                      <div style={{ display: "flex", marginBottom: "16px" }}>
                        <Title
                          level={5}
                          style={{
                            whiteSpace: "nowrap",
                            margin: "0px 0px 0px 16px",
                          }}
                        >
                          {activeTab === "Home"
                            ? "Installation "
                            : activeTab === "Installation Photos"
                            ? "Creative "
                            : activeTab === "Uninstallation Photos"
                            ? "Uninstallation "
                            : "Monitoring "}
                          :{" "}
                          {this.getStatus()?.count +
                            "/" +
                            this.getStatus()?.total}
                        </Title>

                        <Progress
                          percent={this.getStatus()?.percent}
                          strokeWidth={24}
                          strokeColor={"#52c41a"}
                          style={{ margin: "0px 16px" }}
                        />

                        <Button
                          icon={<DownloadOutlined />}
                          onClick={() =>
                            this.setState({ isExportReportDrawer: true })
                          }
                          className="icon-btn"
                        >
                          Download
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}

                <ListFiltersPublic
                  items={filterColumns}
                  handleSettings={this.handleSettings}
                  handleFilters={this.handleFilters}
                  key={filterColumns}
                  screen={screen}
                />

                <Table
                  columns={columns}
                  rowKey={(record) =>
                    record?.ha_inventory_id +
                    "-" +
                    record?.operation_id +
                    "-" +
                    record?.operation_monitoring_id
                  }
                  dataSource={inventory_details}
                  onChange={this.handleTableChange}
                  bordered
                  scroll={{ x: 400 }}
                  size="small"
                  pagination={false}
                  rowClassName={(record) => this.setColors(record)}
                  key={activeTab}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}

        <Drawer
          title={"Export Data"}
          placement="right"
          open={isExportReportDrawer}
          onClose={() => this.setState({ isExportReportDrawer: false })}
          width={"40%"}
        >
          <ExportReport
            {...this.state}
            publicId={this?.props?.match?.params?.id}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default PlanView;
