import React from "react";
import PageTitleDetails from "../../components/common/page-title-detail";
import {
  Row,
  Col,
  Spin,
  Descriptions,
  Card,
  Button,
  Typography,
  Drawer,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import {
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExportOutlined,
  UnorderedListOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import { dateFormatter } from "../../components/common/common-formatter";
import PlanBusiness from "../../components/plan/plan-business";
import { currencyFormatter } from "../../components/common/common-formatter";
import CampaignInventories from "../../components/campaign/campaign-inventories";
import PlanDocuments from "../../components/plan/plan-documents";
import PortalUser from "../../iam/components/portal-user";
import CampaignNotifications from "../../components/campaign/campaign-notifications";
import PlanCampaignDetailsBase from "../../base/plan-campaign-details-base";
import PlanActions from "../../components/plan/plan-actions";
import AddCreative from "../../components/operations/add-creative";
import { RolesPermissions } from "../../helpers/roles-permissions";
import ExportSettings from "../plan/export-settings";
import ExportReport from "./export-report";
class CampaignDetails extends PlanCampaignDetailsBase {
  state = {
    results: "",
    isLoading: true,
    isDrawer: false,
    customersList: [],
    editDrawer: "",
    isEditMode: false,
    isModal: false,
    record: "",
    page: "campaign",
    isHelpModel: false,
    isExportDrawer: false,
    isUpdateKey: 1,
    isExportReportDrawer: false,
  };

  // on component load get details based on id from url
  componentDidMount() {
    this.getCampaignDetails();
    this.getCustomers();
    this.getConfigurationDetails();
  }

  render() {
    const {
      results,
      isLoading,
      isDrawer,
      isBusinessLoading,
      isInventoryLoading,
      editDrawer,
      isEditMode,
      isModal,
      record,
      isHelpModel,
      isExportDrawer,
      isUpdateKey,
      exportType,
      isExportReportDrawer,
    } = this.state;
    const { Title } = Typography;

    return (
      <Row className="plan-details">
        <Col span={24}>
          {!isLoading && (
            <React.Fragment>
              {!isEditMode && (
                <React.Fragment>
                  <PageTitleDetails
                    titleText={
                      results?.project_id +
                      " - " +
                      results.customer_name +
                      " - " +
                      results.display_name +
                      " - " +
                      results.campaign_status
                    }
                    isBackBtn="true"
                    handleBack={this.handleBack}
                    is_rotational={results?.is_rotational}
                  />

                  <div className="page-title-custom-btn plan-dtn-title-btn">
                    {!results.is_approved && RolesPermissions("ad") && (
                      <React.Fragment>
                        <Button
                          type="primary"
                          className="custom-btn-approve"
                          onClick={() => this.handleApprove("approve")}
                        >
                          Approve
                        </Button>

                        <Button
                          type="primary"
                          className="custom-btn-reject"
                          onClick={() => this.handleApprove("reject")}
                        >
                          Reject
                        </Button>

                        <Button
                          type="primary"
                          shape="circle"
                          icon={<InfoOutlined />}
                          style={{ marginRight: "12px" }}
                          onClick={this.handleHelp}
                        />
                      </React.Fragment>
                    )}

                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "email",
                            icon: <ExportOutlined />,
                            label: "Manage Email Notifications"
                          },
                          {
                            key: "report",
                            icon: <ExportOutlined />,
                            label: "Download Campaign Report"
                          },
                          {
                            key: "publiclink",
                            icon: <ExportOutlined />,
                            label: "View Public Link"
                          },
                          {
                            key: "estimate",
                            icon: <ExportOutlined />,
                            label: "Generate Estimate"
                          },
                          {
                            key: "contract",
                            icon: <ExportOutlined />,
                            label: "Campaign Contract"
                          },
                          {
                            key: "download",
                            icon: <DownloadOutlined />,
                            label: "Download PPTx"
                          },
                          {
                            key: "downloadZip",
                            icon: <DownloadOutlined />,
                            label: "Download Photos"
                          },
                          {
                            key: "downloadXlsx",
                            icon: <DownloadOutlined />,
                            label: "Download Excel"
                          },
                          {
                            key: "activity",
                            icon: <UnorderedListOutlined />,
                            label: "Campaign Activity"
                          }
                        ],
                        onClick: this.handleMenuClick
                      }}
                      // overlay={
                      //   <Menu onClick={(key) => this.handleMenuClick(key)}>
                      //     <Menu.Item key="email" icon={<ExportOutlined />}>
                      //       Manage Email Notifications
                      //     </Menu.Item>

                      //     {/* <Menu.Item key="segment" icon={<ExportOutlined />}>
                      //       Assign Segment
                      //     </Menu.Item> */}

                      //     <Menu.Item key="report" icon={<ExportOutlined />}>
                      //       Download Campaign Report
                      //     </Menu.Item>

                      //     <Menu.Item key="publiclink" icon={<ExportOutlined />}>
                      //       View Public Link
                      //     </Menu.Item>

                      //     {/* <Menu.Item key="notes" icon={<ExportOutlined />}>
                      //       Campaign Notes
                      //     </Menu.Item> */}

                      //     <Menu.Item key="estimate" icon={<ExportOutlined />}>
                      //       Generate Estimate
                      //     </Menu.Item>

                      //     <Menu.Item key="contract" icon={<ExportOutlined />}>
                      //       Campaign Contract
                      //     </Menu.Item>

                      //     <Menu.Item
                      //       key={"download"}
                      //       icon={<DownloadOutlined />}
                      //     >
                      //       Download PPTx
                      //     </Menu.Item>
                      //     <Menu.Item
                      //       key={"downloadZip"}
                      //       icon={<DownloadOutlined />}
                      //     >
                      //       Download Photos
                      //     </Menu.Item>
                      //     <Menu.Item
                      //       key={"downloadXlsx"}
                      //       icon={<DownloadOutlined />}
                      //     >
                      //       Download Excel
                      //     </Menu.Item>

                      //     <Menu.Item
                      //       key="activity"
                      //       icon={<UnorderedListOutlined />}
                      //     >
                      //       Campaign Activity
                      //     </Menu.Item>

                      //     {/* <Menu.Item key="creative" icon={<ExportOutlined />}>
                      //       Add Additional Creative
                      //     </Menu.Item> */}
                      //   </Menu>
                      // }
                      placement="bottomRight"
                      trigger={["click"]}
                    >
                      <Button
                        style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      >
                        <EllipsisOutlined />
                      </Button>
                    </Dropdown>
                  </div>

                  <Row gutter={16} className="plan-dtl-boxes">
                    <Col span={8} xs={24} xl={8}>
                      <Card
                        title={<Title level={5}>Business</Title>}
                        extra={
                          !results?.is_completed && (
                            <Button
                              style={{ margin: "-8px" }}
                              icon={<EditOutlined />}
                              onClick={() => this.handleDrawer("Business")}
                            />
                          )
                        }
                        loading={isBusinessLoading}
                        bodyStyle={{ paddingBottom: "0px" }}
                      >
                        <Descriptions column={1}>
                          <Descriptions.Item label="Employee">
                            <b>
                              <PortalUser user={results.employee_id} />
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Start Date">
                            <b>{dateFormatter(results.start_date)}</b>
                          </Descriptions.Item>

                          <Descriptions.Item label="End Date">
                            <b>{dateFormatter(results.end_date)}</b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Days">
                            <b>{results.no_of_days}</b>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>

                      <Card
                        title={<Title level={5}>Inventory</Title>}
                        extra={
                          <Button
                            style={{ margin: "-8px" }}
                            icon={<EditOutlined />}
                            onClick={this.handleEdit}
                          />
                        }
                        bodyStyle={{ paddingBottom: "0px" }}
                        style={{ marginTop: "16px" }}
                        loading={isInventoryLoading}
                      >
                        <Descriptions column={1}>
                          <Descriptions.Item label="Home Inventory">
                            <b>{results.total_ha_inventory}</b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Traded Inventory">
                            <b>{results.total_ta_inventory}</b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Total Sites">
                            <b>{results.total_sites}</b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Price / SQFT">
                            <b>{results.rate_per_sqft}</b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Price/SQFT/Month">
                            <b>{results.rate_per_sqft_month}</b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Total SQFT">
                            <b>{results.total_sqft}</b>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>

                    <Col span={8} xs={24} xl={8}>
                      <Card
                        title={<Title level={5}>Statistics</Title>}
                        bodyStyle={{ paddingBottom: "0px" }}
                        loading={isInventoryLoading}
                      >
                        <Descriptions column={1}>
                          <Descriptions.Item
                            label="HA Markup"
                            className="ant-text-right"
                          >
                            <b
                              className={
                                results.base_rate_diff > 0
                                  ? "ant-color-green"
                                  : "ant-color-red"
                              }
                            >
                              {results.base_rate_diff_amt
                                ? currencyFormatter(
                                    parseInt(results.base_rate_diff_amt)
                                  )
                                : 0}{" "}
                              ({results.base_rate_diff}%)
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Occupancy Wise">
                            <b>{}</b>
                          </Descriptions.Item>

                          <Descriptions.Item
                            label="TA Markup"
                            className="ant-text-right"
                          >
                            <b
                              className={
                                results?.ta_base_rate_diff > 0
                                  ? "ant-color-green"
                                  : "ant-color-red"
                              }
                            >
                              {results?.ta_base_rate_diff_amt
                                ? currencyFormatter(
                                    parseInt(results?.ta_base_rate_diff_amt)
                                  )
                                : 0}{" "}
                              ({results?.ta_base_rate_diff}%)
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item label="ROI on Expense">
                            <b>{}</b>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>

                      <Card
                        title={<Title level={5}>Operations</Title>}
                        bodyStyle={{ paddingBottom: "0px" }}
                        style={{ marginTop: "16px" }}
                      >
                        <Button
                          onClick={this.handleOperations}
                          style={{ marginBottom: "24px" }}
                        >
                          Operations Details
                        </Button>
                      </Card>

                      <Card
                        title={<Title level={5}>Invoice</Title>}
                        bodyStyle={{ paddingBottom: "0px" }}
                        style={{ marginTop: "16px" }}
                      >
                        Coming Soon!
                        <br />
                        <br />
                      </Card>
                    </Col>

                    <Col span={8} xs={24} xl={8}>
                      <Card
                        title={<Title level={5}>Summary</Title>}
                        bodyStyle={{ paddingBottom: "0px" }}
                        loading={isInventoryLoading}
                      >
                        <Descriptions column={1}>
                          <Descriptions.Item
                            label="Display Cost"
                            className="ant-text-right"
                          >
                            <b>
                              {currencyFormatter(
                                parseInt(results.total_display_cost)
                              )}
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item
                            label="Printing Cost"
                            className="ant-text-right"
                          >
                            <b>
                              {currencyFormatter(
                                parseInt(results.total_printing_cost)
                              )}
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item
                            label="Installation Cost"
                            className="ant-text-right"
                          >
                            <b>
                              {currencyFormatter(
                                parseInt(results.total_installing_cost)
                              )}
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item
                            label="Total Without Tax"
                            className="ant-text-right"
                          >
                            <b>
                              {currencyFormatter(
                                parseInt(
                                  results.total_display_cost +
                                    results.total_printing_cost +
                                    results.total_installing_cost
                                )
                              )}
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item
                            label={"GST (" + results.gst_per + "%)"}
                            className="ant-text-right"
                          >
                            <b>
                              {currencyFormatter(parseInt(results.gst_amount))}
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item
                            label={<b>Grand Total</b>}
                            className="ant-text-right"
                            style={{
                              borderTop: "solid 1px #f0f0f0",
                              paddingTop: "12px",
                            }}
                          >
                            <b>
                              {currencyFormatter(
                                parseInt(results.total_amount)
                              )}
                            </b>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>

                      <Card
                        title={<Title level={5}>Documents</Title>}
                        extra={
                          <Button
                            style={{ margin: "-8px" }}
                            icon={<EditOutlined />}
                            onClick={() => this.handleDrawer("Documents")}
                          />
                        }
                        bodyStyle={{ paddingBottom: "0px" }}
                        style={{ marginTop: "16px" }}
                      >
                        <Descriptions column={1}>
                          <Descriptions.Item label="Email Confirmation">
                            <b>
                              {results.documents &&
                                results.documents["email_confirmation"]?.length}
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Purchase Orders">
                            <b>
                              {results.documents &&
                                results.documents["purchase_order"]?.length}
                            </b>
                          </Descriptions.Item>

                          <Descriptions.Item label="Others">
                            <b>
                              {results.documents &&
                                results.documents["others"]?.length}
                            </b>
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                  </Row>

                  <Drawer
                    title={editDrawer}
                    placement="right"
                    open={isDrawer}
                    onClose={() => this.handleDrawer("")}
                    width={"40%"}
                  >
                    {editDrawer === "Business" && (
                      <PlanBusiness
                        {...this.state}
                        handleBusiness={this.handleBusiness}
                        key={isDrawer}
                      />
                    )}

                    {editDrawer === "Documents" && (
                      <PlanDocuments {...this.state} />
                    )}
                  </Drawer>
                </React.Fragment>
              )}

              {isEditMode && (
                <CampaignInventories
                  {...this.state}
                  handleInventories={this.handleInventories}
                  key={isUpdateKey}
                  handleInventory={this.handleInventory}
                  handleBack={this.handleEdit}
                  handleChangeOfSite={this.handleChangeOfSite}
                  handleAdjustCampaignDatesToggle={
                    this.handleAdjustCampaignDatesToggle
                  }
                />
              )}

              <Modal
                centered
                title={record.title}
                open={isModal}
                onCancel={this.handleModal}
                footer={false}
                width={record.action !== "creative" ? 620 : 920}
              >
                {(record.action === "email" ||
                  record.action === "segment" ||
                  record.action === "notes") && (
                  <CampaignNotifications
                    key={isModal}
                    {...this.state}
                    handleModal={this.handleNotificationModal}
                  />
                )}

                {record.action === "activity" && (
                  <PlanActions
                    key={isModal}
                    {...record}
                    {...this.state}
                    handleActions={this.handleActions}
                  />
                )}

                {record.action === "creative" && (
                  <AddCreative
                    key={isModal}
                    {...this.state}
                    handleAddCreative={this.handleModal}
                  />
                )}
              </Modal>

              <Modal
                centered
                title={"Help"}
                open={isHelpModel}
                onCancel={this.handleHelp}
                footer={false}
              >
                <div>
                  <h3>
                    <b>
                      Please Read This Carefully Before Approving / Rejecting
                      Campaign
                    </b>
                  </h3>

                  <div style={{ marginTop: "16px" }}>
                    <h4>
                      <b>Plan to Campaign</b>
                    </h4>
                    <span>
                      Approval Flow - Plan will be approved and converted to
                      Campaign
                    </span>
                    <br />
                    <span>
                      Rejection Flow - Campaign will be converted back to Plan.
                      Employee will be notified on email.
                    </span>
                  </div>

                  <div style={{ marginTop: "16px" }}>
                    <h4>
                      <b>Adding Not-Available / Blocked Site to Campaign</b>
                    </h4>
                    <span>
                      Approval Flow - Newly added sites will be added to
                      Campaign
                    </span>
                    <br />
                    <span>
                      Rejection Flow - Newly added sites will be removed from
                      Campaign
                    </span>
                  </div>

                  <div style={{ marginTop: "16px" }}>
                    <h4>
                      <b>Changing Rates of Sites</b>
                    </h4>
                    <span>
                      Approval Flow - New rates will be approved and campaign
                      will be updated
                    </span>
                    <br />
                    <span>
                      Rejection Flow - New rates will be rejected and campaign
                      will be updated with old rates
                    </span>
                  </div>
                </div>
              </Modal>

              <Drawer
                title={"Export"}
                placement="right"
                open={isExportDrawer}
                onClose={() => this.setState({ isExportDrawer: false })}
                width={"34%"}
              >
                <ExportSettings {...this.state} key={exportType} />
              </Drawer>

              <Drawer
                title={"Export Campaign Report"}
                placement="right"
                open={isExportReportDrawer}
                onClose={() => this.setState({ isExportReportDrawer: false })}
                width={"40%"}
              >
                <ExportReport {...this.state} />
              </Drawer>
            </React.Fragment>
          )}

          {isLoading && (
            <div className="list-loader">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default CampaignDetails;
