export const monthly = [
  {
    id: "1",
    type: "Realised (billed)",
    april: "80000",
    may: "88000",
    june: "81600",
    july: "89760",
    august: "80000",
    september: "84000",
    october: "87000",
    november: "89000",
    december: "82000",
    january: "84000",
    february: "86000",
    march: "87000",
    total: "1008000",
  },
  {
    id: "2",
    type: "Unrealised (unbilled)",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
];

export const cityWise = [
  {
    id: "1",
    type: "Ahmedabad",
    april: "80000",
    may: "88000",
    june: "81600",
    july: "89760",
    august: "80000",
    september: "84000",
    october: "87000",
    november: "89000",
    december: "82000",
    january: "84000",
    february: "86000",
    march: "87000",
    total: "1008000",
  },
  {
    id: "2",
    type: "Surat",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "3",
    type: "Rajkot",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "4",
    type: "Vapi",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
];

export const payoutWise = [
  {
    id: "1",
    type: "AMTS Bus Shelter",
    april: "80000",
    may: "88000",
    june: "81600",
    july: "89760",
    august: "80000",
    september: "84000",
    october: "87000",
    november: "89000",
    december: "82000",
    january: "84000",
    february: "86000",
    march: "87000",
    total: "1008000",
  },
  {
    id: "2",
    type: "AMC Kiosk",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "3",
    type: "Hubtown",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "4",
    type: "Vapi GIDC",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "5",
    type: "GSRTC",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
];

export const employeeWise = [
  {
    id: "1",
    type: "Ravi Patel",
    april: "80000",
    may: "88000",
    june: "81600",
    july: "89760",
    august: "80000",
    september: "84000",
    october: "87000",
    november: "89000",
    december: "82000",
    january: "84000",
    february: "86000",
    march: "87000",
    total: "1008000",
  },
  {
    id: "2",
    type: "Manas Dubey",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "3",
    type: "Shailesh Chauhan",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "4",
    type: "Haresh Chauhan",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
  {
    id: "5",
    type: "Bhadresh Shah",
    april: "20000",
    may: "21000",
    june: "24600",
    july: "26760",
    august: "20000",
    september: "24000",
    october: "27000",
    november: "29000",
    december: "22000",
    january: "24000",
    february: "26000",
    march: "27000",
    total: "344000",
  },
];

export const drillDown = [
  {
    id: "1",
    type: "Realised (billed)",
    iid: "H-0098",
    city: "Ahmedabad",
    location: "SBR, Armieda - 30x32 BL",
    from: "01/01/2024",
    to: "01/01/2024",
    monthly_rate: "50000",
    days: "20",
    amount: "33333",
    customer: "Dentsu",
    display: "Suncity",
    employee: "Ravi Patel",
    invoice_date: "01/01/2024",
    invoice_number: "cooh/009",
  },
  {
    id: "2",
    type: "Unrealised (unbilled)",
    iid: "H-0098",
    city: "Ahmedabad",
    location: "SBR, Armieda - 30x32 BL",
    from: "01/01/2024",
    to: "01/01/2024",
    monthly_rate: "60000",
    days: "20",
    amount: "22000",
    customer: "Dentsu",
    display: "Suncity",
    employee: "Ravi Patel",
    invoice_date: "01/01/2024",
    invoice_number: "",
  },
];

export const graphData = [
  {
    month: "April 24",
    type: "Realised",
    amount: 80000,
  },
  {
    month: "April 24",
    type: "Unrealised",
    amount: 20000,
  },
  {
    month: "May 24",
    type: "Realised",
    amount: 88000,
  },
  {
    month: "May 24",
    type: "Unrealised",
    amount: 21000,
  },
  {
    month: "June 24",
    type: "Realised",
    amount: 81600,
  },
  {
    month: "June 24",
    type: "Unrealised",
    amount: 24600,
  },
  {
    month: "July 24",
    type: "Realised",
    amount: 89760,
  },
  {
    month: "July 24",
    type: "Unrealised",
    amount: 26760,
  },
  {
    month: "August 24",
    type: "Realised",
    amount: 84000,
  },
  {
    month: "August 24",
    type: "Unrealised",
    amount: 20000,
  },
  {
    month: "September 24",
    type: "Realised",
    amount: 87000,
  },
  {
    month: "September 24",
    type: "Unrealised",
    amount: 24000,
  },
  {
    month: "October 24",
    type: "Realised",
    amount: 89000,
  },
  {
    month: "October 24",
    type: "Unrealised",
    amount: 27000,
  },
  {
    month: "November 24",
    type: "Realised",
    amount: 82000,
  },
  {
    month: "November 24",
    type: "Unrealised",
    amount: 29000,
  },
  {
    month: "December 24",
    type: "Realised",
    amount: 84000,
  },
  {
    month: "December 24",
    type: "Unrealised",
    amount: 22000,
  },
  {
    month: "January 25",
    type: "Realised",
    amount: 86000,
  },
  {
    month: "January 25",
    type: "Unrealised",
    amount: 24000,
  },
  {
    month: "February 25",
    type: "Realised",
    amount: 87000,
  },
  {
    month: "February 25",
    type: "Unrealised",
    amount: 26000,
  },
  {
    month: "March 25",
    type: "Realised",
    amount: 88000,
  },
  {
    month: "March 25",
    type: "Unrealised",
    amount: 27000,
  },
];
