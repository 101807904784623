import React, { Component } from "react";
import { Drawer, Table, Tabs } from "antd";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import {
  monthly,
  cityWise,
  payoutWise,
  employeeWise,
  drillDown,
  graphData,
} from "./../../helpers/dashboard-static";
import PageTitle from "../../components/common/page-title";
import { Column } from "@ant-design/plots";

class DashboardTabs extends Component {
  state = {
    data: graphData,
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    isDrawer: false,
    selectedMonth: "",
  };

  handleTitle = (res) => {
    this.setState({ isDrawer: !this.state.isDrawer, selectedMonth: res });
  };

  // Setting Columns
  getColumns = (type) => {
    let columns = [
      {
        title: type,
        dataIndex: "type",
      },
      {
        title: () => {
          return (
            <div className="link-hdr" onClick={() => this.handleTitle("april")}>
              April 24
            </div>
          );
        },
        dataIndex: "april",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div className="link-hdr" onClick={() => this.handleTitle("may")}>
              May 24
            </div>
          );
        },
        dataIndex: "may",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div className="link-hdr" onClick={() => this.handleTitle("june")}>
              June 24
            </div>
          );
        },
        dataIndex: "june",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div className="link-hdr" onClick={() => this.handleTitle("july")}>
              July 24
            </div>
          );
        },
        dataIndex: "july",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleTitle("august")}
            >
              August 24
            </div>
          );
        },
        dataIndex: "august",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleTitle("september")}
            >
              September 24
            </div>
          );
        },
        dataIndex: "september",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleTitle("october")}
            >
              October 24
            </div>
          );
        },
        dataIndex: "october",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleTitle("november")}
            >
              November 24
            </div>
          );
        },
        dataIndex: "november",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleTitle("december")}
            >
              December 24
            </div>
          );
        },
        dataIndex: "december",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleTitle("january")}
            >
              January 24
            </div>
          );
        },
        dataIndex: "january",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleTitle("february")}
            >
              February 24
            </div>
          );
        },
        dataIndex: "february",
        render: (text) => currencyFormatter(text),
      },
      {
        title: () => {
          return (
            <div className="link-hdr" onClick={() => this.handleTitle("march")}>
              March 24
            </div>
          );
        },
        dataIndex: "march",
        render: (text) => currencyFormatter(text),
      },
      {
        title: "Total",
        dataIndex: "total",
        render: (text) => currencyFormatter(text),
      },
    ];

    return columns;
  };

  // Setting Columns
  getColumnsDrill = (type) => {
    let columns = [
      {
        title: type,
        dataIndex: "type",
      },
      {
        title: "IID",
        dataIndex: "iid",
      },
      {
        title: "City",
        dataIndex: "city",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "From",
        dataIndex: "from",
        render: (text) => dateFormatter(text),
      },
      {
        title: "To",
        dataIndex: "to",
        render: (text) => dateFormatter(text),
      },
      {
        title: "Monthly Rate",
        dataIndex: "monthly_rate",
        render: (text) => currencyFormatter(text),
      },
      {
        title: "Days",
        dataIndex: "days",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (text) => currencyFormatter(text),
      },
      {
        title: "Customer",
        dataIndex: "customer",
      },
      {
        title: "Display",
        dataIndex: "display",
      },
      {
        title: "Employee",
        dataIndex: "employee",
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text) => dateFormatter(text),
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_number",
      },
    ];

    return columns;
  };

  setData = () => {
    this.setState({ data: monthly || [] });
  };

  // Handle Drawer
  handleDrawer = (record) => {
    this.setState({ isDrawer: !this.state.isDrawer, record });
  };

  componentDidMount() {
    // this.setColumns();
    // this.setData();
  }

  render() {
    const { data, loading, pagination, isDrawer } = this.state;
    const { TabPane } = Tabs;
    return (
      <div>
        <PageTitle titleText="Dashboard" />
        <div style={{ height: "480px", marginBottom: "42px" }}>
          <Column
            {...{
              data,
              group: true,
              xField: "month",
              yField: "amount",
              seriesField: "type",
              colorField: "type",
            }}
          />
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Monthly Sales" key="1" forceRender="true">
            <Table
              columns={this.getColumns("Sales Type")}
              rowKey={(record) => record.id}
              dataSource={monthly}
              pagination={pagination}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </TabPane>
          <TabPane tab="City Wise" key="2" forceRender="true">
            <Table
              columns={this.getColumns("City Name")}
              rowKey={(record) => record.id}
              dataSource={cityWise}
              pagination={pagination}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </TabPane>
          <TabPane tab="Payout Estimate Wise" key="3" forceRender="true">
            <Table
              columns={this.getColumns("Payout Estimate Name")}
              rowKey={(record) => record.id}
              dataSource={payoutWise}
              pagination={pagination}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </TabPane>
          <TabPane tab="Employee Wise" key="4" forceRender="true">
            <Table
              columns={this.getColumns("Employee Name")}
              rowKey={(record) => record.id}
              dataSource={employeeWise}
              pagination={pagination}
              loading={loading}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </TabPane>
        </Tabs>

        <Drawer
          title={"Monthly Sales"}
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          key={isDrawer}
          width="88%"
        >
          <Table
            columns={this.getColumnsDrill("Sales Type")}
            rowKey={(record) => record.id}
            dataSource={drillDown}
            pagination={pagination}
            loading={loading}
            bordered
            scroll={{ x: 400 }}
            size="small"
          />
        </Drawer>
      </div>
    );
  }
}

export default DashboardTabs;
